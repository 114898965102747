<script lang="ts">
	export let name: string;
</script>
<body>
    <main>
    	<h1>{name}</h1>
    </main>
</body>

<style>
    :global(body) {
        background-color: #232831;
    }
    
    main {
        background-color: #232831;
		text-align: center;
	}

	h1 {
		color: #D8E0BB;
		text-transform: uppercase;
		font-size: 4em;
		font-weight: 800;
	}
</style>
